import React, { useState } from "react";
import productsData from "../../db/products.json";
import { IphonesSection, Card, CardImage } from "./Iphones";
import {
  Button,
  HoverableSpan,
  StyledCenter,
  StyledOuterButton,
} from "./Hover";
import {
  CloseButton,
  ModalTitle,
  ProductImage,
  ModalText,
  ModalContent,
  ModalOverlay,
  ModalSubTitle,
} from "../../components/Modal/Modal";
import { WhatsAppButton } from "../../components/WhatsAppButton";

const Iphones = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleCardClick = (product) => {
    setSelectedProduct(product);
  };

  // const openModal = (product) => {
  //   setSelectedProduct(product);
  // };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <IphonesSection>
      {productsData.products.map((product) => (
        <Card key={product.id} onClick={() => handleCardClick(product)}>
          <CardImage src={product.img_url} alt={product.modelo} />

          <StyledCenter>
            <StyledOuterButton>
              <Button>{product.modelo}</Button>
              <HoverableSpan></HoverableSpan>
              <HoverableSpan></HoverableSpan>
            </StyledOuterButton>
          </StyledCenter>
        </Card>
      ))}

      {selectedProduct && (
        <ModalOverlay onClick={handleOutsideClick}>
          <ModalContent>
            <CloseButton onClick={closeModal}>Fechar</CloseButton>
            <ModalTitle>{selectedProduct.modelo}</ModalTitle>
            <ModalSubTitle>{selectedProduct.armazenamento}</ModalSubTitle>
            <ProductImage
              src={selectedProduct.img_url}
              alt={selectedProduct.modelo}
              aria-label={selectedProduct.modelo}
            />

            <ModalText>Preço: R$ {selectedProduct.price}</ModalText>
            <ModalText>{selectedProduct.price2}</ModalText>
            <WhatsAppButton>
              <a
                href={`https://api.whatsapp.com/send?phone=5551998157507&text=Ol%C3%A1%20MJN%20Imports,%20estou%20interessado%20no%20${encodeURIComponent(
                  selectedProduct.modelo
                )}%20${encodeURIComponent(selectedProduct.armazenamento)}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="MJN Imports Whatsapp"
              >
                Contato via WhatsApp
              </a>
            </WhatsAppButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </IphonesSection>
  );
};

export default Iphones;

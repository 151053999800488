import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Oswald', sans-serif;
  }
  
  body {
    margin: 0;
    padding: 0;
    /* width: 100vw; */
    height: auto;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: var(--dark);
    
    @media only screen and (max-width: 600px) {
      width: 100vw;
      height: auto;
      max-height: 100vh;
    }
  }

:root {
  --grey: #060126;
  --dark: #0D0D0D;
  --brown: #8C5E35;
  --gold: #BF9C5A;
  --yellow: #F2E49B;
}
  
`;

export default GlobalStyle;

import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import {
  StyledDevelopedBy,
  StyledDiv,
  StyledFooter,
  StyledIconItem,
  StyledIconList,
  StyledRightsReserved,
} from "./Footer";

function Footer() {
  return (
    <StyledFooter>
      <StyledIconList>
        <StyledIconItem>
          <a
            href="https://www.instagram.com/mjn.imports/"
            target="_blank"
            rel="noreferrer"
            alt="Instagram MJN Imports"
            aria-label="Instagram MJN Imports"
          >
            <FaInstagram />
          </a>
        </StyledIconItem>

        <StyledIconItem>
          <a
            href="https://api.whatsapp.com/send?phone=555198157507"
            target="_blank"
            rel="noreferrer"
            alt="WhatsApp  MJN Imports"
            aria-label="WhatsApp  MJN Imports"
          >
            <FaWhatsapp />
          </a>
        </StyledIconItem>
      </StyledIconList>
      <StyledDiv>
        <StyledRightsReserved>
          Horário de Atendimento: <br></br>Segunda-feira a Sábado, das 10h às
          20h
        </StyledRightsReserved>
        <StyledRightsReserved>
          © 2023 Direitos Reservados |{" "}
          <a
            href="https://www.mjnimports.com.br/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Ir para o MJN Imports"
          >
            <span>MJN Imports</span>
          </a>
        </StyledRightsReserved>
      </StyledDiv>
      <StyledDevelopedBy>
        Desenvolvido por{" "}
        <a
          href="https://www.edtech1985.com.br/criacao-de-sites"
          target="_blank"
          rel="noreferrer"
          alt="Desenvolvedor Web edtech1985"
          aria-label="Criação de Websites edtech1985"
        >
          <span>
            <strong>edtech1985</strong>
          </span>
        </a>
      </StyledDevelopedBy>
    </StyledFooter>
  );
}

export default Footer;

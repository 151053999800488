import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Main } from "./Routes";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Iphones from "../pages/Iphones";
import Watches from "../pages/Watches";
import AirPods from "../pages/AirPods";
import FAQ from "../pages/FAQ";
import Contact from "../pages/Contact";

export default function AppRouter() {
  return (
    <Main>
      {/* <StyledBackground /> */}
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/iphones" element={<Iphones />} />
          <Route path="/relogios" element={<Watches />} />
          <Route path="/duvidas" element={<FAQ />} />
          <Route path="/airpods" element={<AirPods />} />
          <Route path="/contato" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </Main>
  );
}

import styled from "styled-components";

export const WhatsAppButton = styled.button`
  background-color: #25d366; /* Cor do WhatsApp */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: background-color 0.75s ease-in-out;

  &:hover {
    background-color: var(--dark);
    color: var(--yellow);
    border: 2px solid var(--yellow);
  }

  a {
    text-decoration: none;
    color: #fff;
  }
`;

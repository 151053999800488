import styled, { keyframes } from "styled-components";

export const AirPodsSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: var(--dark);
  margin-top: 1rem;
  width: 97vw;

  @media (min-width: 769px) and (max-width: 999px) {
    /* justify-content: center; */
  }

  @media (max-width: 481px) {
    display: block;
  }
`;

export const Card = styled.div`
  width: calc(25% - 1rem); /* 4 cards por linha no desktop */
  margin-bottom: 2rem;
  position: relative;
  transition: transform 0.3s ease-in-out;

  @media (min-width: 769px) and (max-width: 999px) {
    width: calc(33% - 1rem); /* 3 cards por linha no tablet */
  }

  @media (min-width: 481px) and (max-width: 769px) {
    width: calc(49% - 1rem); /* 2 cards por linha no tablet */
    margin-left: 1rem;
  }

  @media (max-width: 481px) {
    width: 90%; /* 1 card por linha no mobile */
  }

  &:hover {
    transform: scale(1.05);
  }
`;

const pulsate = keyframes`
  0%, 100% {
    border-color: var(--gold);
  }
  50% {
    border-color: var(--yellow);
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: auto;
  margin: 1rem;
  border: 5px solid transparent;
  border-radius: 1.25rem;
  transition: border-color 0.75s ease-in-out;

  ${Card}:hover & {
    border-color: var(--gold); /* Efeito luminoso no hover */
    animation: ${pulsate} 1.5s infinite; /* Aplicando a animação de pulsar */
  }
`;
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const ModalContent = styled.div`
  background-color: var(--dark);
  padding: 1rem;
  border-radius: 15px;

  box-shadow: 2px 4px 16px rgba(191, 156, 90, 0.5);
  width: 35rem;
  max-height: 80vh;
  overflow: auto;
  position: relative;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  border: 5px solid;
  border-color: var(--gold); /* Efeito luminoso no hover */
  animation: ${pulsate} 1.5s infinite; /* Aplicando a animação de pulsar */

  @media (min-width: 769px) and (max-width: 999px) {
    width: 70vw;
  }

  @media (max-width: 481px) {
    width: 80vw;
  }
`;

export const ModalTitle = styled.h2`
  color: var(--gold);
  margin-top: 0.75rem;
`;

export const ModalText = styled.p`
  color: var(--yellow);
  margin-bottom: 0.5rem;
`;

export const ProductImage = styled.img`
  max-width: 100%;
  max-height: 15rem;
  margin: 2rem;
  border-radius: 15px;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  color: var(--gold);
  border: none;
  position: absolute;
  font-size: 1.25rem;
  top: 10px;
  right: 10px;

  cursor: pointer;

  &:hover {
    color: red;
    font-weight: bold;
  }
`;

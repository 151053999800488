import styled, { keyframes } from "styled-components";

export const IphonesSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: var(--dark);
  margin-top: 1rem;
  width: 97vw;

  @media (min-width: 769px) and (max-width: 999px) {
    /* justify-content: center; */
  }

  @media (max-width: 481px) {
    display: block;
  }
`;

export const Card = styled.div`
  width: calc(25% - 1rem); /* 4 cards por linha no desktop */
  margin-bottom: 2rem;
  position: relative;
  transition: transform 0.3s ease-in-out;

  @media (min-width: 769px) and (max-width: 999px) {
    width: calc(33% - 1rem); /* 3 cards por linha no tablet */
  }

  @media (min-width: 481px) and (max-width: 769px) {
    width: calc(49% - 1rem); /* 2 cards por linha no tablet */
    margin-left: 1rem;
  }

  @media (max-width: 481px) {
    width: 90%; /* 1 card por linha no mobile */
  }

  &:hover {
    transform: scale(1.05);
  }
`;

const pulsate = keyframes`
  0%, 100% {
    border-color: var(--gold);
  }
  50% {
    border-color: var(--yellow);
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: auto;
  margin: 1rem;
  border: 5px solid transparent;
  border-radius: 1.25rem;
  transition: border-color 0.75s ease-in-out;

  ${Card}:hover & {
    border-color: var(--gold); /* Efeito luminoso no hover */
    animation: ${pulsate} 1.5s infinite; /* Aplicando a animação de pulsar */
  }
`;

export const CardModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fundo escuro semi-transparente */
  justify-content: center;
  align-items: center;
  z-index: 1000;

  flex-direction: column; /* Novo estilo para centralizar verticalmente */

  display: flex; /* Adicione essa linha para alinhar os elementos horizontalmente */

  .modal-image {
    width: 50%; /* Metade da largura para a imagem */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .modal-image img {
    max-width: 100%;
    max-height: 100%;
  }

  .modal-info {
    width: 50%; /* Metade da largura para as informações */
    padding: 20px;
    color: #fff;
    text-align: left;
  }

  .close-button {
    background-color: var(--gold);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
      background-color: var(--brown);
    }
  }

  .whatsapp-button {
    background-color: #25d366; /* Cor do WhatsApp */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
      background-color: #128c7e; /* Cor de destaque ao passar o mouse */
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 10px;
    color: #fff; /* Cor do texto */
  }

  button {
    background-color: var(--gold);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background-color: var(--brown);
    }
  }
`;

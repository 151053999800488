import styled, { keyframes } from "styled-components";
// import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

export const HomePage = styled.div`
  padding: 20px;
  text-align: center;
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const WelcomeParagraph = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 0.25rem;
  color: var(--gold);

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const WelcomeH2 = styled.h2`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--gold);

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 15px;
  }
`;

export const CallToAction = styled.div`
  text-align: center;
  margin-top: 1rem;
  color: var(--yellow);

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const CallToActionText = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const StyledALink = styled.a`
  text-decoration: none;
`;

export const CallToActionLink = styled.button`
  vertical-align: middle;
  color: white;
  background-color: green;
  padding: 1rem 2rem;
  border-radius: 2rem;
  font-weight: bold;
  transition: background-color 0.75s ease-in-out;
  cursor: pointer;

  &:hover {
    color: var(--yellow);
    background-color: var(--dark);
    border: 2px solid var(--yellow);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

export const WhatsAppIcon = styled(FaWhatsapp)`
  &:hover {
    background-color: var(--dark);
    color: var(--yellow);
  }
`;

export const RoundIcon = styled.div`
  img {
    color: var(--gold);
  }
`;

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  NavbarTitle,
  StyledHeader,
  StyledLogo,
  StyledNavbar,
  StyledNavItem,
  BurgerMenuButton,
} from "./Header";
import logo from "../../img/logo.png";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNavItemClick = () => {
    setIsOpen(false);
  };

  return (
    <StyledHeader>
      <Link to="/">
        <StyledLogo src={logo} alt="MJN Imports" />
      </Link>
      <Link
        as={NavLink}
        to="/"
        exact
        onClick={handleNavItemClick}
        alt="MJN Imports"
        aria-label="MJN Imports"
      >
        <NavbarTitle>
          <strong> MJN Imports </strong>
        </NavbarTitle>
      </Link>

      <BurgerMenuButton
        id="BurgerButtonMenu"
        open={isOpen}
        onClick={handleMenuClick}
      >
        <span></span>
        <span></span>
        <span></span>
      </BurgerMenuButton>

      <StyledNavbar open={isOpen}>
        <StyledNavItem as={NavLink} to="/" exact onClick={handleNavItemClick}>
          Home
        </StyledNavItem>

        <StyledNavItem
          as={NavLink}
          to="/iphones"
          alt="iphones"
          aria-label="iphones"
          onClick={handleNavItemClick}
        >
          iPhones
        </StyledNavItem>

        <StyledNavItem
          as={NavLink}
          to="/relogios"
          alt="relógios"
          aria-label="relógios"
          onClick={handleNavItemClick}
        >
          Watches
        </StyledNavItem>

        <StyledNavItem
          as={NavLink}
          to="/airpods"
          alt="airpods"
          aria-label="airpods"
          onClick={handleNavItemClick}
        >
          AirPods
        </StyledNavItem>

        <StyledNavItem
          as={NavLink}
          to="/duvidas"
          alt="Dúvidas Frequentes"
          aria-label="Dúvidas Frequentes"
          onClick={handleNavItemClick}
        >
          Dúvidas
        </StyledNavItem>

        <StyledNavItem
          as={NavLink}
          to="/contato"
          alt="Contatos"
          aria-label="Contatos"
          onClick={handleNavItemClick}
        >
          Contatos
        </StyledNavItem>

        <StyledNavItem></StyledNavItem>
      </StyledNavbar>
    </StyledHeader>
  );
}

export default Header;

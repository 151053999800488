import React from "react";
import { Container, Titulo, IconesContato, ItemContato } from "./Contact";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";

export default function Contact() {
  return (
    <Container>
      <Titulo>Contatos</Titulo>
      <IconesContato>
        <ItemContato>
          <a
            href="https://www.instagram.com/mjn.imports/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="MJN Imports Instagram"
          >
            <FaInstagram size={40} color="#E1306C" />
            <span>Instagram</span>
          </a>
        </ItemContato>
        <ItemContato>
          <a
            href="https://api.whatsapp.com/send?phone=5551998157507"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="MJN Imports Whatsapp 1"
          >
            <FaWhatsapp size={40} color="#25D366" />
            <span>Whatsapp Vendedor 1</span>
          </a>
        </ItemContato>
        <ItemContato>
          <a
            href="https://api.whatsapp.com/send?phone=5551985467725"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="MJN Imports Whatsapp 2"
          >
            <FaWhatsapp size={40} color="#25D366" />
            <span>Whatsapp Vendedor 2</span>
          </a>
        </ItemContato>
      </IconesContato>
    </Container>
  );
}

import React from "react";
import {
  WelcomeParagraph,
  CallToAction,
  CallToActionText,
  WelcomeH2,
  CallToActionLink,
  StyledALink,
  WhatsAppIcon,
  // RoundIcon,
} from "./Home";
import { HomePage } from "./Home";

const Home = () => {
  return (
    <HomePage>
      <WelcomeParagraph>
        Bem-vindo à nossa loja de importados! <br></br>
      </WelcomeParagraph>
      <WelcomeH2>
        Explore produtos incríveis das marcas Apple, Xiaomi e Samsung.
      </WelcomeH2>
      <CallToAction>
        <CallToActionText>Pronto para começar?</CallToActionText>
        <StyledALink
          href="https://api.whatsapp.com/send?phone=5551998157507&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%E2%80%A6"
          target="_blank"
          rel="noreferrer"
          alt="WhatsApp  MJN Imports"
          aria-label="WhatsApp  MJN Imports"
        >
          <CallToActionLink>
            <WhatsAppIcon />
            &nbsp; Entre em contato via WhatsApp &nbsp;
            <WhatsAppIcon />
          </CallToActionLink>
        </StyledALink>
      </CallToAction>
      {/* <div>
        <RoundIcon>
          <img
            src="https://icon-library.com/images/iphone-icon-png/iphone-icon-png-13.jpg"
            alt="iphone"
          ></img>
        </RoundIcon>
      </div> */}
    </HomePage>
  );
};

export default Home;

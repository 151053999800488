import styled from "styled-components";

export const FAQSectionContainer = styled.div`
  width: 80vw;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--gold);

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Question = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--gold);

  &:hover {
    color: var(--yellow);
  }
`;

export const Answer = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: var(--yellow);
`;
